.reveal.not-revealed {
  opacity: 1;
  transform: translateY(0);
}

.floor-description .btn,
.carousel-indicators,
.carousel-control,
.carousel-control .icon,
#section-external-view .apartment:hover:after,
#section-external-view .apartment:hover:before,
.apartment .item:hover:after,
.apartment .item:hover:before,
.zoom:hover:after,
.zoom:hover:before,
#map {
  display: none;
}

.apartment {
  cursor: default;
}

#section-map .col-md-5 {
  width: 100%;
}